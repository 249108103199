<template>
  <div class="container-fluid container">
    <Breadcrumb>
      <template slot="restPage">
        <!-- <el-breadcrumb-item>
          <router-link :to="`/private/${$route.params.privateType}`">
            {{
              `${
                isCounseling
                  ? $t("pageTitle.counseling")
                  : $t("pageTitle.privateLessons")
              }`
            }}
          </router-link>
        </el-breadcrumb-item> -->
        <el-breadcrumb-item>
          <router-link
            :to="
              isPrivateLesson
                ? `/private/${$route.params.privateType}/${$route.params.classId}/detail`
                : `/session-classes/${$route.params.classId}`
            "
          >
            <IsOnlineIcon
              :isOnline="report.session_class.course_session.is_online"
            />
            {{ lessonTitle }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ thePageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h3>
      {{ thePageTitle }}
      <router-link
        :to="
          `/${routePath}/${$route.params.classId}/progress-report/${$route.params.reportId}/edit`
        "
      >
        <el-button class="mr-2" type="primary" size="mini">
          <i class="el-icon-edit-outline" />
        </el-button>
      </router-link>
      <el-tooltip
        class="item"
        content="mail report to teacher"
        placement="top"
        popper-class="tooltipColor"
      >
        <el-button
          @click="
            () => {
              $router.push({
                name: 'EmailProgressReport',
                query: {
                  class_progress_report_id: $route.params.reportId,
                  target: 'teacher'
                }
              });
            }
          "
          type="primary"
          size="mini"
        >
          <i class="el-icon-message" />
        </el-button>
      </el-tooltip>
      <DeleteIconButton :onDelete="deleteClassReport($route.params.reportId)" />
    </h3>
    <el-divider />
    <el-row>
      <el-col :span="3">Teacher:</el-col>
      <el-col :span="20">
        <span>{{
          `${this.report.teacher_user.first_name} ${this.report.teacher_user.last_name}`
        }}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="3">Student(s):</el-col>
      <el-col :span="20">
        <UserNames
          :users="
            report.student_progress_reports.map(({ student_user }) => ({
              id: student_user.id,
              name: `${student_user.first_name} ${student_user.last_name}`
            }))
          "
        />
      </el-col>
    </el-row>
    <h4>Lesson logs</h4>
    <el-tabs v-model="currenctLessonTab" @tab-click="() => {}">
      <el-tab-pane name="teacher" v-if="isRoleAdmin() || isRoleTeacher()">
        <template slot="label">
          <i class="fas fa-user-graduate" />
          {{ teacher.name }}
        </template>
        <TeachingLogs
          :isPrivateLesson="isPrivateLesson"
          :classId="Number($route.params.classId)"
          :teachingLogs="teachingLogs"
          :teacherUserId="teacher.id"
          :teacherUserName="teacher.name"
          @reloadCustomLesson="fetchReport"
        />
      </el-tab-pane>
      <template>
        <el-tab-pane
          :key="student.id"
          v-for="{ student } in students"
          :label="student.name"
          :name="String(student.id)"
        >
          <template slot="label">
            <i class="far fa-user" />
            {{ student.name }}
          </template>
          <LessonLogs
            :isPrivateLesson="isPrivateLesson"
            :classId="Number($route.params.classId)"
            :lessonLogs="lessonLogs[student.id]"
            :enrollSessionId="getEnrollSessionId(student.id)"
            @reloadCustomLesson="fetchReport"
          />
        </el-tab-pane>
      </template>
    </el-tabs>
    <h4>{{ `${reportColumnNames[report.time_range_type]}:` }}</h4>
    <div>{{ report.report }}</div>
    <h4>{{ `${planForNextColumnNames[report.time_range_type]}:` }}</h4>
    <div>{{ report.plan }}</div>
    <h4>Notes:</h4>
    <el-tabs v-if="report" v-model="currenctNoteTab" @tab-click="() => {}">
      <el-tab-pane
        :key="studentReport.student_user_id"
        v-for="studentReport in report.student_progress_reports"
        :name="String(studentReport.student_user_id)"
      >
        <template slot="label">
          <i class="far fa-user" />
          {{ studentReport.student_user.name }}
        </template>
        <pre>{{ studentReport.note_to_student }}</pre>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import TeachingLogs from "@/views/lessonLog/TeachingLogs";
import LessonLogs from "@/views/lessonLog/LessonLogs";
import UserNames from "@/components/names/UserNames";
import IsOnlineIcon from "@/components/icon/IsOnline";
import privateApi from "@/apis/private";
import reportApi from "@/apis/report";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import {
  timeRange,
  reportColumnNames,
  planForNextColumnNames
} from "./dictionarys";

export default {
  metaInfo() {
    return {
      title: this.thePageTitle
    };
  },
  mixins: [moneyMixin, roleMixin],
  components: {
    Breadcrumb,
    DeleteIconButton,
    TeachingLogs,
    LessonLogs,
    UserNames,
    IsOnlineIcon
  },
  computed: {
    isCounseling() {
      return this.$route.params.privateType === "counseling";
    },
    routePath() {
      if (!this.isPrivateLesson) return "session-class";
      if (this.privateType === "counseling") {
        return "private/counseling";
      }
      return "private/private-lessons";
    },
    thePageTitle() {
      if (!this.report) return "";
      return `${timeRange[this.report.time_range_type]} Report - ${
        this.lessonTitle
      }[${this.report.from} ~ ${this.report.to}]`;
    },
    lessonTitle() {
      return this.report.session_class[
        this.isPrivateLesson ? "subject" : "title"
      ];
    },
    isPrivateLesson() {
      return this.$route.name === "privateClassesReport";
    },
    timeRange() {
      return timeRange;
    },
    reportColumnNames() {
      return reportColumnNames;
    },
    planForNextColumnNames() {
      return planForNextColumnNames;
    }
  },
  data() {
    return {
      report: null,
      teacher: null,
      students: [],
      currenctNoteTab: null,
      currenctLessonTab: "teacher",
      teachingLogs: [],
      lessonLogs: []
    };
  },
  async created() {
    await this.initialExchangeRate();
    this.fetchReport();
  },
  methods: {
    async fetchReport() {
      const {
        class_progress_report: report,
        teaching_logs_group_by_user,
        lesson_logs_group_by_user
      } = await reportApi.fetchReport(this.$route.params.reportId);
      this.report = report;
      this.currenctNoteTab = String(
        report.student_progress_reports[0].student_user_id
      );
      this.teacher = report.teacher_user;
      this.teachingLogs = teaching_logs_group_by_user[report.teacher_user.id];
      this.lessonLogs = lesson_logs_group_by_user;

      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.classId
      });
      this.students = customLesson.student_class_tickets;
    },
    deleteClassReport(classReportId) {
      return async () => {
        await reportApi.deleteClassReport(classReportId);
        if (this.isPrivateLesson) {
          this.$router.push({
            path: `/private/${this.$route.params.privateType}/${this.$route.params.classId}/detail`
          });
        } else {
          this.$router.push({
            name: "classDetail",
            params: {
              classId: this.$route.params.classId
            }
          });
        }
      };
    },
    getEnrollSessionId(studentId) {
      return this.students.find(
        student => student.student_user_id === studentId
      ).enrolled_session_id;
    }
  }
};
</script>

<style scoped>
h4 {
  margin-top: 20px;
}
.container {
  margin-bottom: 40px;
}
</style>
